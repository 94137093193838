
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































































































































































































































































.organization-partnerships {
  .chevron-icon {
    @apply tw-transform tw--rotate-90 tw-duration-300;

    &.is-active {
      @apply tw-rotate-90;
    }
  }

  &-pagination {
    @include pagination;
  }

  .el-radio-button {
    @apply mfw:tw-w-auto tw-w-1/2 focus:tw-shadow-none;
  }

  .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    @apply focus:tw-shadow-none;
  }

  .el-radio-button__inner {
    @apply tw-bg-jb-grey-50 tw-text-jb-grey-400 tw-text-sm hover:tw-text-jb-grey-400 tw-w-full;
  }

  .el-radio-button.is-active .el-radio-button__inner {
    @apply hover:tw-text-white;
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    @apply tw-bg-jb-indigo tw-border-jb-indigo tw-shadow-none;
  }

  .partnership-list {
    .menu-icon {
      @apply tw-text-jb-grey-400;

      &.is-active {
        @apply tw-text-jb-indigo;
      }
    }

    .el-collapse-item {
      @apply tw-mb-space-1;
    }

    .el-collapse-item__wrap {
      @apply tw-rounded-none;
    }

    .el-collapse-item__header,
    .el-collapse-item__content {
      @apply tw-p-0 tw-rounded-none;
    }
  }
}

.member-dropdown {
  @apply tw-p-0 tw-border-none tw-rounded-none;

  &[x-placement^="bottom"] {
    @apply tw-mt-space-1;
  }

  &[x-placement^="top"] {
    @apply tw-mb-space-1;
  }
}
